import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { NotFoundComponent } from './core/components/not-found/not-found.component'

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./feature/redwhale-admin/redwhale-admin.module').then((m) => m.RedwhaleAdminModule),
    },

    { path: '404', component: NotFoundComponent },

    { path: '**', redirectTo: '/404' },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
