import { Injectable } from '@angular/core'

import { User } from '@models/user'

@Injectable({ providedIn: 'root' })
export class StorageService {
    private storage: Storage
    private userKey: string

    constructor() {
        this.storage = sessionStorage
        this.userKey = 'user'
    }

    getUser(): User {
        return JSON.parse(this.storage.getItem(this.userKey))
    }

    setUser(user: User): void {
        this.storage.setItem(this.userKey, JSON.stringify(user))
    }

    removeUser() {
        this.storage.removeItem(this.userKey)
    }
}
